import { template as template_8b155a3f3e524e578bac269f70f66a2e } from "@ember/template-compiler";
const FKText = template_8b155a3f3e524e578bac269f70f66a2e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
