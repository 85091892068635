import { template as template_e7dcbf62fddb4f8584df8b0d6f8851cd } from "@ember/template-compiler";
const WelcomeHeader = template_e7dcbf62fddb4f8584df8b0d6f8851cd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
