import { template as template_d7b7c19cf03a41738d374fcd1d4ce815 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import PluginOutlet from "discourse/components/plugin-outlet";
const CategoryTitleBefore = template_d7b7c19cf03a41738d374fcd1d4ce815(`
  <PluginOutlet
    @name="category-title-before"
    @outletArgs={{hash category=@category}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryTitleBefore;
